<template>
<div>
    <v-dialog v-model="$store.state.dialog3" width="800">
        <v-card flat class="card">
            <v-toolbar outlined flat >
                <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>

                            <v-row>

                                <v-col cols="12" md="6" > 
                                    <h3 class="mb-2">المحافظة <span class="required">*</span></h3>
                                    <v-autocomplete v-model="item.governorateId" @input="getAreas()" :rules="[$global.state.required()]" clearable item-text="name" item-value="id" :items="$global.state.governorates" class="mr-1" placeholder="المحافظة" :loading="$global.state.loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
                                </v-col>
                                
                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">المناطق<span class="required">*</span></h3>
                                    <v-autocomplete chips multiple v-model="item.areaId" :search-input.sync="search" :rules="[$global.state.required()]" v-debounce:500ms="getAreas" clearable item-text="name" item-value="id" :items="areas" class="mr-1" placeholder="المناطق" :loading="$global.state.loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
                                </v-col>

                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :disabled="!valid" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {

    data() {
        return {
            item: {
                "userId": "",
                "areaId": [],
                "governorateId": ""
            },
            search: '',
            valid: false,
            areas:[],
            uploading: false,
        }
    },

    created() {
        this.reset()
        this.$eventBus.$on('fill-fields', () => {
            this.reset()
            this.getAreas()
            if(this.$store.state.itemDetails.userAreas.length > 0){
                this.item.areaId = []
                for (let i = 0; i < this.$store.state.itemDetails.userAreas.length; i++) {
                    this.item.areaId.push(this.$store.state.itemDetails.userAreas[i].area.id)
                }
            }
        })
        this.getAreas()
    },

    methods: {

        reset() {
            this.item = {
                "userId": "",
                "areaId": [],
                "governorateId": ""
            }
        },

        submit() {
            this.item.userId = this.$store.state.itemDetails.id
            if (this.$store.state.itemDetails.dialogType == 'add') {
                this.addItem()
            } else {
                this.editItem()
            }
        },

        addItem() {
            this.$global.state.loading = true
            this.$http.post(`User`, this.item).then(res => {
                this.$service.success('تم الإضافة بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.close()
            }).catch(err => {
                // this.$service.faild(err.response.data.message)
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        editItem() {
            this.$global.state.loading = true
            this.$http.put(`User/assignAreas`, this.item).then(res => {
                this.close()
                this.$service.success('تم التعديل بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
            }).catch(err => {
                // this.$service.faild(err.response.data.message)
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },


        async getAreas() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Area?PageIndex=1&PageSize=1000&GovernorateId=${this.item.governorateId}`)
                this.areas = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        close() {
            this.$store.commit('setDialog3')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>

